document.addEventListener('DOMContentLoaded', function () {
  // Check if any video buttons exist on the page
  const videoButtons = document.querySelectorAll('[data-video-player][data-video-id]');
  // if no video buttons, no need to proceed
  if (videoButtons.length === 0) {
    return;
  }

  let ytScriptLoaded = false;

  function loadYouTubeAPI() {
    if (ytScriptLoaded) {
      return;
    }
    ytScriptLoaded = true;
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';
    document.head.appendChild(script);
  }

  function playVideo(videoId, container) {
    new YT.Player(container, {
      videoId: videoId,
      events: {
        onReady: (event) => event.target.playVideo()
      }
    });
  }

  // Store pending video plays in case API is not yet ready
  const pendingVideos = [];

  // Handle YouTube API readiness
  window.onYouTubeIframeAPIReady = function () {
    ytScriptLoaded = true;
    pendingVideos.forEach(({ videoId, container }) => playVideo(videoId, container));
  };

  videoButtons.forEach(button => {
    button.addEventListener('click', function () {
      loadYouTubeAPI();
      const videoId = this.getAttribute('data-video-id');
      const container = document.createElement('div');
      this.replaceWith(container); // Replace button with video player

      if (typeof YT !== 'undefined' && YT.Player) {
        // API is ready, play video
        playVideo(videoId, container);
      } else {
        // API not ready, queue the video play
        pendingVideos.push({ videoId, container });
      }
    });
  });
});
